import React, { useEffect, useState } from "react"
import Seo from "../../core/Seo"
import { Content, Section } from "../../core/LayoutComponents"

const BookMichellePage = () => {
  const [iframeHeight, setIframeHeight] = useState(1800)

  return (
    <Section>
      <Seo title="We Sparkle • Book Michelle" includeZohoTracking />
      <Content>{/* <h1>Book Michelle</h1> */}</Content>

      <iframe
        frameBorder="0"
        allowtransparency="true"
        style={{
          height: iframeHeight + "px",
          width: "100%",
          border: "none",
        }}
        src="https://forms.zohopublic.com/wesparkleco/form/BookMichelle/formperma/PGBipfX_x-eGTlHGs9C5MGR14_XQt831BPu9smqiiZs"
        name="book_michelle_zoho_iframe"
      ></iframe>
    </Section>
  )
}

export default BookMichellePage
